@import "../variables";

.emoji-slider-container {
  --roundness: 15px;
  display: grid;
  justify-content: stretch;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.emoji-slider {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  width: 100%;
  position: relative;
  z-index: 1;
  opacity: 0;
  height: calc(var(--roundness) * 2);
  cursor: pointer;
}

:root {
  --white: #fff;
}

.outer {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  width: 100%;
  border-radius: var(--roundness);
  margin: auto;
  height: var(--roundness);
  background: var(--white);
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}

label.inner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--white);
  border-top-left-radius: var(--roundness) !important;
  border-bottom-left-radius: var(--roundness) !important;
  position: absolute;
  transition: all 0.3s cubic-bezier(0.5, 0.4, 0.2, 1);
  text-align: right;
  font-size: calc(var(--roundness) * 2);
  line-height: 1;

  span {
    position: absolute;
    right: -2px;
    top: calc(50% - var(--roundness) * 2);
    top: calc(var(--roundness) * -1.1);
    transition: inherit;
    font-size: 3rem;
  }
}
