@import "../variables";
@import "../mixins";

.proposal-wrapper {
  background: black;
  padding: 1rem;
  display: flex;
  flex: 1 100% !important;
  margin: 2rem 0;
  border: 1px solid #737373;
  border-radius: 1rem;

  @include size-medium-down {
    flex-direction: column;
  }

  &:first-of-type {
    border-bottom: none;
    border-radius: 1rem 1rem 0 0;
    margin-bottom: 0;

    @media only screen and (min-width: em($bp-xl)) {
      margin-top: 3.5rem;
    }
  }

  &:last-of-type {
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    margin-top: 0;
  }
}

.proposal-wrapper-alt {
  background: black;
  padding: 1rem;
  display: flex;
  flex: 1 100% !important;
  margin: 2rem 0;
  border: 1px solid #737373;
  border-radius: 1rem;

  @include size-medium-down {
    flex-direction: column;
  }

  @media only screen and (min-width: em($bp-xl)) {
    margin-top: 3.5rem;
  }
}

.proposal-content {
  padding: 1.5rem 5rem 1.5rem 1rem;
  flex: 0 55% !important;
  min-width: 55% !important;
  word-break: break-word;

  @include size-medium-down {
    padding: 1rem;
  }

  p,
  span {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      12px,
      16px
    ); /* min font-size 0.75rem, max 1rem */

    line-height: 1.6;
    white-space: pre-line;
  }
}

.proposal-content-title {
  font-size: 1.4rem;
  font-weight: 900;
  margin: 0 0 1.5rem;
}

.proposal-details {
  padding: 1.5rem 2rem;
  flex: 0 45% !important;

  @include size-medium-down {
    padding: 1rem;
  }

  button {
    font-family: "Lexend Exa", sans-serif;
    border: none;
    background: #242424;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    flex: 1 1 100%;
    padding: 1rem;
    font-size: 0.9rem;
    border-radius: 1rem;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;

      div {
        float: left;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 1.25rem;
    }
  }
}

.eth-requested-wrapper {
  text-align: center;
  margin-bottom: 2.5rem;
}

.eth-requested {
  color: #242424;
  background-color: #6ce460;
  border-radius: 12px;
  padding: 0.5rem 1.2rem;
  display: inline-block;
  font-size: 0.75rem;

  > span {
    display: block;
    margin: 0.25rem;
  }
}

.proposal-actions-container {
  padding: 0;
}

.proposal-data-container {
  border: 1px solid #737373;
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 3rem;
  word-break: break-word;

  &.proposal-data-container--alt {
    margin-top: 6rem;

    @include size-medium-down {
      margin-top: 4rem;
    }
  }
}

.proposal-data-title {
  font-size: 1.125rem;
  font-weight: 900;
  margin-bottom: 2rem;
}

.button-group {
  text-align: right;
}

.back-button {
  display: block;
  position: absolute;
  right: 0;
  top: 60px;
  background-color: #e83adc;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.proposer-info {
  padding: 0 0 1rem;
}

.proposer-info__title {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #656565;
}

.proposer-info__address {
  word-break: break-word;
}

.privacy {
  padding: 1.25em 0 0;
  width: 100%;

  &__settings {
    align-items: center;
    display: flex;
  }

  &__text {
    font-size: 0.8em;
  }

  &__confirmMessage {
    color: $color-brightlime;
    font-size: 0.75em;
    position: relative;
    top: -1.5em;
  }
}

.info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  word-wrap: break-word;

  &:first-of-type {
    margin-top: 2rem;
  }

  span:first-child {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #656565;
  }
}

.info-item-list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }

    li:last-of-type {
      margin-bottom: 0;
    }
  }
}

.social-link-icons-list {
  display: flex;
  margin-top: 1em;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-right: 1.125em;
    margin-bottom: 0;
  }

  li:last-child {
    margin-right: 0;
  }
}
