@import "../mixins";
@import "../variables";

.section-header {
  font-size: 20px;
  text-decoration: underline;
}

li {
  font-size: 18px;
  margin-bottom: 1rem;
}

// tables
table {
  border-collapse: collapse;
  font-size: 0.9em;
  overflow-x: scroll;
  display: block;
}

tr {
  border-bottom: 1px solid #ddd;
}

th:not(:last-child) {
  border-right: 1px solid #ddd;
}

th,
td {
  text-align: left;
  padding: 6px;
}

td {
  background-color: transparent;

  &:not(:last-child) {
    border-right: 1px solid #ddd;
  }
}
