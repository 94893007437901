@import "../variables";

.input-wrap {
  display: flex;
  position: relative;
  z-index: 1;

  input {
    background: transparent;
    flex: none;
    padding-right: 3rem;
  }
}

.button {
  -webkit-appearance: none;
  align-self: center;
  background: none;
  border: none;
  display: block;
  left: -3.2rem;
  line-height: 0;
  padding: 1rem;
  position: relative;
}

.button-menu svg {
  height: 1rem;
  width: 1rem;
}

.button-close svg {
  height: 1rem;
  width: 1rem;
}

.menu-wrap {
  position: relative;
}

.menu {
  background: black;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 1px 0 0 black inset, 0 0px 0 1px $color-babyblue inset;
  font-family: $font-system-sans;
  font-size: 1.125em;
  list-style: none;
  margin: -1.25rem 0 0 0;
  max-height: 8.5rem;
  overflow-y: auto;
  padding: calc(1.125em + 1.125em) 1.125em 1.125em;
  position: absolute;
  width: calc(100% - 2.4rem - 2px);
  z-index: 0;
}

.menu--closed {
  box-shadow: none;
  margin: 0;
  padding: 0;
}

%itemBase {
  cursor: pointer;
  padding-top: 1rem;

  &:first-of-type {
    padding-top: 0;
  }
}

.item {
  @extend %itemBase;

  color: #fff;

  &:active,
  &:hover,
  &:focus,
  &[aria-selected="true"] {
    color: $color-babyblue;
  }
}

.item--default {
  @extend %itemBase;

  color: $color-litesmoke;
}
