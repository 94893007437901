@import "../variables";
@import "../mixins";

.row {
  margin-top: 3.75rem;
  max-width: 37.5em;

  &:hover {
    cursor: pointer;
  }

  @include size-medium-down {
    margin-top: 3rem;
  }
}

.proposalNameRow {
  margin-bottom: 2rem;
  align-items: center;
  display: flex;

  svg,
  img {
    flex-shrink: 0;
    margin-right: 2rem;

    @include size-medium-down {
      margin-right: 1.25rem;
    }
  }

  .proposalName {
    font-size: 1.25rem;
    word-break: break-word;
  }
}

.memberVote {
  color: #d7d7d7;
  padding-left: 1.25rem;

  li {
    list-style: none;
    display: inline-block;
    font-size: 1rem;

    @include size-medium-down {
      font-size: 0.85rem;
    }

    &:nth-child(2) {
      padding-left: 2rem;
      margin-left: 2rem;

      @include size-medium-down {
        padding-left: 0.8rem;
        margin-left: 0.8rem;
      }
    }
  }

  .pending {
    span {
      position: relative;
      padding-left: 3rem;

      &:before {
        content: "\2026 ";
        display: block;
        position: absolute;
        top: -12px;
        left: 0;
        font-size: 1.6rem;
        color: white;
      }
    }
  }

  .passed {
    span {
      position: relative;
      padding-left: 3rem;

      &:before {
        content: "\2713 ";
        display: block;
        position: absolute;
        top: -5px;
        left: 0;
        font-size: 1.4rem;
        color: #61d1ff;
      }
    }
  }

  .failed {
    span {
      position: relative;
      padding-left: 3rem;

      &:before {
        content: "\00D7 ";
        display: block;
        position: absolute;
        top: -15px;
        left: 0;
        font-size: 2.2rem;
        color: #ed9eff;
      }
    }
  }

  .yes {
    color: #61d1ff;
    margin-left: 1rem;
  }

  .no {
    color: #ed9eff;
    margin-left: 1rem;
  }

  @include size-medium-down {
    .pending,
    .passed,
    .failed {
      span {
        padding-left: 2rem;
      }
    }
    .yes,
    .no {
      margin-left: 0.75rem;
    }
  }
}
