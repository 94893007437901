@import "../variables";
@import "../functions";

.error {
  // @extend :global(".error-message");

  font-size: 1rem;
  margin-top: 0.5rem;

  @media only screen and (min-width: em($bp-md)) {
    font-size: 1.125rem;
  }
}
