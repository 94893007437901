@import "../variables";
@import "../mixins";

.contract {
  color: #e0e0e0; /* ease bright white on the eyes */
  font-family: "Times New Roman", Times, serif;
  text-align: justify;

  // override default p
  p {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      12px,
      16px
    ); /* min font-size 0.75rem, max 1rem */

    color: #e0e0e0; /* ease bright white on the eyes */
  }

  :global {
    .align-center {
      text-align: center;
    }
  }
}

.contract-info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  word-wrap: break-word;

  &:first-of-type {
    margin-top: 2rem;
  }

  span:first-child {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #656565;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }

    li:last-of-type {
      margin-bottom: 0;
    }
  }
}

.contract-docs {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.contract-doc-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 1.5rem;
  position: relative;

  svg {
    flex: none;
    height: 2.5rem;
    margin-right: 0.75rem;
    width: 2.5rem;
  }

  span {
    display: flex;
    align-items: center;
    line-height: 2;
    flex: 1 0 80%;
  }
}

.contract-doc-item--disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.contract-doc-text {
  display: flex;
  flex-direction: column;
}

.contract-doc-status,
.contract-doc-retry {
  color: $color-litesmoke;
  font-size: 0.8rem;
}

.contract-doc-status--link {
  color: $color-babyblue;
  text-decoration: underline;
}

.contract-doc-retry {
  align-items: center;
  cursor: pointer;
  display: flex;

  &:active,
  &:hover {
    span {
      color: $color-babyblue;
    }

    .contract-doc-retry__icon {
      transform: rotate(15deg);
      transition: all 0.25s ease-in-out;
    }
  }

  span {
    text-decoration: underline;
  }
}

.contract-doc-retry .contract-doc-retry__icon {
  color: $color-babyblue;
  display: inline-block;
  flex: 0;
  font-size: 1.5rem;
  line-height: 1;
  margin: -0.1rem 0.5rem 0 0;
  text-decoration: none;
}
