@import "../variables";
@import "../functions";

.wrap {
  align-items: center;
  background-color: #131313;
  color: #bbb;
  display: flex;
  min-height: 3rem;
  padding: 2rem 1.5rem;
  position: relative;
  font-size: 0.8rem;

  @media only screen and (min-width: em($bp-lg)) {
    padding: 0 2rem;
  }
}

.left,
.right {
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;

  .item {
    margin: 1rem 0 0 0;

    &:first-child {
      margin: 0;
    }
  }

  @media only screen and (min-width: em($bp-lg)) {
    flex-direction: row;

    .item {
      margin: 0 2rem 0 0;

      &:first-child {
        margin: 0 2rem 0 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.right {
  & > div {
    margin: 2.5rem 0 0 0;
    text-align: center;
  }

  a:first-child {
    margin: 0;
  }

  @media only screen and (min-width: em($bp-lg)) {
    & > div {
      margin: 0;
      text-align: right;
    }
  }
}
