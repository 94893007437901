@import "../variables";
@import "../mixins";

.wrap {
  word-break: break-word;
}

.member-details-container {
  border: 1px solid #737373;
  border-radius: 1rem;
  padding: 2.5rem;
}

.member-section-title {
  font-size: 1.125rem;
  font-weight: 900;
  margin-bottom: 2rem;
}

.header {
  margin-top: 0; /* .divider provides enough spacing */
}

.button {
  /* reset */
  border: none;
  background-color: #242424;
  border-radius: 1rem;
  color: white;
  font-family: inherit;
  margin-top: 1rem;
  min-width: 25%;
  padding: 0.75rem 2.5rem;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.delegation-button {
  border: none;

  background-color: transparent;
  border-radius: 6px;
  box-shadow: 0 0 0 2px #75f86a;
  color: #75f86a;
  min-width: 25%;
  padding: 0.375rem 2rem;
  text-transform: uppercase;
  letter-spacing: 0.0156rem;
  transition: box-shadow 0.25s ease, background-color 0.25s ease;

  &:active,
  &:hover {
    background-color: #75f86a;
    box-shadow: 0 0 0 2px darken(#75f86a, 10%);
    color: #000;
  }
}

.confirm-button span {
  color: #d6d6d6;
}

.list {
  /* reset */
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  li {
    // align-items: center;
    display: flex;
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    svg {
      margin-right: 1.5rem;
    }
  }
}

.account-info-item {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  /* label */
  span:first-child {
    color: $color-litesmoke;
    font-size: 80%;
  }

  /* content */
  span:last-of-type {
    margin-top: 0.5rem;
  }
}

.member-actions-container {
  padding: 0 2.5rem;
  margin-top: 5rem;

  @include size-medium-down {
    padding: 0;
    margin-top: 3rem;
  }
}

.action:not(:first-of-type) {
  margin-top: 3em;
}

button.action__button {
  margin-top: 2rem;
  display: block;
}

.action__help {
  font-size: 90%;
  max-width: 80%;

  @include size-medium-down {
    max-width: none;
  }
}

.voting-delegated {
  color: #a8dca3;
  margin-top: 1rem;
  display: flex;
}

.revoke-delegate {
  text-align: left;
  margin: auto;
  max-width: 80%;

  p svg {
    margin-right: 1rem;
  }

  @include size-medium-down {
    max-width: 90%;
  }
}

.rage-quit-small,
.revoke-delegate-small {
  margin: 2rem 0 2.5rem;
  display: flex;
  color: #969696;
}

.rage-quit-small {
  text-align: center !important;
  max-width: 80%;
  margin: auto auto 2rem;
}

.arrow-down {
  font-size: 2rem;
  text-align: center;
  margin: 0;
}

.delegation-checkbox + label span:nth-child(2) {
  color: #969696;
  font-size: 0.8rem;
  text-align: left;
}

.error-message {
  font-size: 0.95rem;
  color: #ff6d6d !important;
  margin-bottom: 0;
}
