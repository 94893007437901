@import "../variables";
@import "../mixins";
@import "../functions";

/* SHARED */

%font {
  color: white;
  display: block;
  line-height: 1.1;
  outline: none;
  font-family: $font-header--potra;
  font-weight: 200;
}

/* THE */

.the {
  @extend %font;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    32px
  ); /* min font-size 1.5rem, max 3rem */
  animation: theFlicker 1.6s infinite alternate;
  letter-spacing: 0.2rem;
}

.the--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    22px
  ); /* min font-size 2rem, max 3rem */
}

.the--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    32px,
    48px
  ); /* min font-size 2rem, max 3rem */
}

/* LAO */

.lao {
  @extend %font;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    64px,
    96px
  ); /* min font-size 4rem, max 6rem */

  animation: laoFlicker 1.8s infinite alternate;
  font-size: 6em;
  letter-spacing: 0.1rem;
}

.lao--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    34px,
    56px
  ); /* min font-size 6rem, max 9rem */
}

.lao--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    96px,
    144px
  ); /* min font-size 6rem, max 9rem */
}

/* ANIMATION FLICKER */

@keyframes theFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 1rem $color-brightlime, 0 0 2rem $color-brightlime,
      0 0 3rem $color-brightlime, 0 0 4rem $color-brightlime,
      0 0 5rem $color-brightlime;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

@keyframes laoFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 2rem $color-hotpink, 0 0 4rem $color-hotpink, 0 0 6rem $color-hotpink,
      0 0 8rem $color-hotpink, 0 0 10rem $color-hotpink;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

/* FLAMINGO */

.flamingo {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    64px,
    96px
  ); /* min font-size 4rem, max 6rem */

  color: white;
  font-family: "Space Mono", monospace;
  text-transform: uppercase;
  font-size: 6em;
  font-weight: 700;
  text-shadow: 2px 2px 6px #f5a19f;
}

.flamingo--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    30px
  ); /* min font-size 1.5rem, max 1.875rem */
}

.flamingo--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    48px
  ); /* min font-size 1.5rem, max 3rem */
}

.flamingo--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    96px
  ); /* min font-size 3.375rem, max 6rem */
}

/* NEPTUNE */

.neptune img {
  width: 100%;
  height: auto;
}

.neptune--large {
  img {
    max-width: 475px;
  }

  @media only screen and (max-width: em($bp-sm)) {
    padding: 0 1rem;
  }
}

.neptune--medium img {
  max-width: 325px;
}

.neptune--small img {
  max-width: 200px;
}

/* NEON */

.neon img {
  width: 100%;
  height: auto;
  line-height: 0;
  margin-top: 1rem;
}

.neon--large {
  img {
    max-width: 375px;
  }

  @media only screen and (max-width: em($bp-sm)) {
    padding: 0 1rem;
  }
}

.neon--medium img {
  max-width: 200px;
}

.neon--small img {
  max-width: 160px;
  margin-left: 1rem;
}

/* RED DAO */

.reddao {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  color: black;
  font-family: "Didot Regular", serif;
  text-transform: uppercase;
  font-size: 6em;
  font-weight: 700;
  letter-spacing: 18px;
}

.reddao--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    30px
  ); /* min font-size 1.5rem, max 1.875rem */
}

.reddao--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    48px
  ); /* min font-size 1.5rem, max 3rem */
}

.reddao--large {
  line-height: normal;

  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */
}

/* READY PLAYER DAO */

.readyplayerdao {
  img {
    width: 10rem;
  }
}

.readyplayerdao--small {
  img {
    width: 13rem;
  }
}

.readyplayerdao--medium {
  img {
    width: 15rem;
  }
}

.readyplayerdao--large {
  img {
    width: 35rem;
  }
}

/* NOISE DAO */

.noisedao {
  svg {
    width: 10rem;
  }
}

.noisedao--small {
  svg {
    position: relative;
    top: 2rem;
    width: 13rem;
  }
}

.noisedao--medium {
  svg {
    position: relative;
    top: 1rem;
    width: 15rem;
  }
}

.noisedao--large {
  svg {
    width: 35rem;
  }
}

/* SPACESHIP DAO */

.spaceship {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.05em;
  color: #ffffff;
  text-shadow: 0px 6px 40px #d6dea1;
  text-transform: uppercase;
}

.spaceship--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    30px
  ); /* min font-size 1.5rem, max 1.875rem */
}

.spaceship--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    48px
  ); /* min font-size 1.5rem, max 3rem */
}

.spaceship--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */
}

/* UNICORN DAO */

.unicorn {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  font-family: "Russian Land Cyrillic Sinod", serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.125em;
  color: #000000;
  text-transform: uppercase;
}

.unicorn--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    48px,
    52px
  ); /* min font-size 3rem, max 3.25rem */
}

.unicorn--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    48px,
    64px
  ); /* min font-size 3rem, max 4rem */
}

.unicorn--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */
}

/* PUNK DAO */

.punk {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  font-family: "PT Mono", monospace;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: #1d1d1d;
  text-transform: uppercase;
  transform: rotate(-0.15deg);
  text-shadow: 0 3px rgba(0, 0, 0, 0.14);
  background: #ffffff;
  border: 0.5px solid #8e8e8e;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 2rem;
  text-align: center;
}

.punk--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    30px
  ); /* min font-size 1.5rem, max 1.875rem */
}

.punk--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    40px
  ); /* min font-size 1.5rem, max 2.5rem */
}

.punk--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */
}

/* DARK HORSE DAO */

.darkhorse {
  line-height: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.darkhorse--small {
  img {
    width: 110px;
  }
}

.darkhorse--medium {
  img {
    width: 120px;
  }
}

.darkhorse--large {
  img {
    width: 100%;
  }
}

/* BEAKER DAO */

.science {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  font-family: "DotGothic16", sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 12px;
  transform: translateX(6px); /* compensate for large letter spacing */
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: stroke(2, #000000), 0px 4px 4px rgba(3, 3, 3, 0.3);
  line-height: 1;
}

.science--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    32px,
    36px
  ); /* min font-size 2rem, max 2.25rem */
  letter-spacing: 0.25em;
  transform: translateX(0.125em); /* compensate for large letter spacing */
}

.science--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    28px,
    48px
  ); /* min font-size 1.75rem, max 3rem */
  letter-spacing: 0.25em;
  transform: translateX(0.125em); /* compensate for large letter spacing */
}

.science--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    56px,
    80px
  ); /* min font-size 3.5rem, max 5rem */
}

/* c0c0 DAO */

.cc0 {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  line-height: 0;

  span {
    display: none;
  }

  img {
    height: 1.25em;
  }
}

.cc0--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    64px,
    64px
  ); /* min font-size 4rem, max 4rem */

  color: #ffffff;
  font-family: "MarcoSteel", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: translateX(0.05em); /* compensate for large letter spacing */
  line-height: 1;

  span {
    display: initial;
  }

  img {
    display: none;
  }
}

.cc0--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    36px,
    48px
  ); /* min font-size 2.25rem, max 3rem */
}

.cc0--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    56px,
    80px
  ); /* min font-size 3.5rem, max 5rem */
}

/* Si DAO */

.si {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  line-height: 0;

  span {
    display: none;
  }

  img {
    height: 1.25em;
  }
}

.si--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    40px,
    40px
  ); /* min font-size 2.5rem, max 2.5rem */

  color: #ffffff;
  font-family: "Press Start 2P", cursive;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: initial;
  line-height: 1;

  span {
    display: initial;
  }

  img {
    display: none;
  }
}

.si--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    48px,
    72px
  ); /* min font-size 3rem, max 4.5rem */
}

.si--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    56px,
    80px
  ); /* min font-size 3.5rem, max 5rem */
}

/* Glimmer DAO */

.glimmer {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  color: #000000;
  font-family: "ARK-ES", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1.35px;
  transform: translateX(0.675px); /* compensate for large letter spacing */
  line-height: 1;
}

.glimmer--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    36px,
    36px
  ); /* min font-size 2.25rem, max 2.25rem */
}

.glimmer--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    28px,
    36px
  ); /* min font-size 1.75rem, max 2.25rem */
}

.glimmer--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    56px,
    80px
  ); /* min font-size 3.5rem, max 5rem */
}

/* Test DAO */

.test {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    76px
  ); /* min font-size 3.375rem, max 4.75rem */

  line-height: 0;

  span {
    display: none;
  }

  img {
    height: 1.25em;
  }
}

.test--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    40px,
    40px
  ); /* min font-size 2.5rem, max 2.5rem */

  color: #ffffff;
  font-family: "Press Start 2P", cursive;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: initial;
  line-height: 1;

  span {
    display: initial;
  }

  img {
    display: none;
  }
}

.test--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    48px,
    72px
  ); /* min font-size 3rem, max 4.5rem */
}

.test--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    56px,
    80px
  ); /* min font-size 3.5rem, max 5rem */
}

/* v2 DAOs with shared standard styles */

.daoV2 {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    56px,
    76px
  ); /* min font-size 3.5rem, max 4.75rem */

  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  transform: translateX(0.08em); /* compensate for large letter spacing */
  line-height: 1;
}

.daoV2--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    36px,
    36px
  ); /* min font-size 2.25rem, max 2.25rem */
}

.daoV2--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    40px
  ); /* min font-size 1.5rem, max 2.5rem */
}

.daoV2--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    56px,
    76px
  ); /* min font-size 3.5rem, max 4.75rem */
}
