// font stacks
$font-system-sans: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-body: "Lexend Exa", $font-system-sans;
$font-header: "Lexend Exa", $font-system-sans;
$font-header--potra: Potra, $font-system-sans;
$font-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace;
$font-serif: "Times New Roman", Times, "Georgia", serif;

// widths
$width-wide: 1200px;
$width-max-content: 1125px;

// colors
$color-babyblue: #6ef4fa;
$color-babypink: #e083f6;
$color-brightlime: #75f86a;
$color-brightsalmon: #ff6f6f;
$color-cirrus: #c5ddef;
$color-darksmoke: #333333;
$color-deepsmoke: #212121;
$color-fadedsky: #5da8d2;
$color-hotpink: #e83adc;
$color-keylargo: #31cde7;
$color-lilac: #4952a5;
$color-litesmoke: #939393;
$color-offwhite: #fafafa;
$color-purple: #742f9e;
$color-royal: #076ad2;
$color-sunny: #f5f650;
$color-wiltedlilac: #828092;

$colors: (
  "babyblue": $color-babyblue,
  "babypink": $color-babypink,
  "brightlime": $color-brightlime,
  "brightsalmon": $color-brightsalmon,
  "cirrus": $color-cirrus,
  "darksmoke": $color-darksmoke,
  "deepsmoke": $color-deepsmoke,
  "fadedsky": $color-fadedsky,
  "hotpink": $color-hotpink,
  "keylargo": $color-keylargo,
  "lilac": $color-lilac,
  "litesmoke": $color-litesmoke,
  "offwhite": $color-offwhite,
  "purple": $color-purple,
  "royal": $color-royal,
  "sunny": $color-sunny,
  "wiltedlilac": $color-wiltedlilac,
);

// breakpoints (via bootstrap)
$bp-sm: 576px !default;
$bp-md: 768px !default;
$bp-lg: 992px !default;
$bp-xl: 1200px !default;
