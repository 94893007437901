@import "../variables";
@import "../functions";

%button-base {
  background-color: transparent;
  border: 3px solid;
  border-radius: 3em;
  font-size: rem(18);
  font-family: $font-body;
  padding: 1em em(28);
}

button {
  cursor: pointer;
  outline: none;
}

.primary,
.secondary {
  @extend %button-base;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.primary {
  border-color: $color-babyblue;
  color: $color-babyblue;
}

.secondary {
  border-color: white;
  color: white;
  transition: border-color 0.25s ease;

  &:hover {
    border-color: $color-litesmoke;
    color: white;
  }

  &--small {
    font-size: 0.8rem;
  }
}

.underline {
  // reset
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;

  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-decoration: underline;
  transition: all 0.25s ease-in-out;

  &:hover {
    filter: brightness(0.6);
  }
}

.underline-blue {
  @extend .underline;

  color: $color-babyblue;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: darken($color-babyblue, 33%);
  }
}

.tertiary {
  background: url("../../images/back-arrow.svg") no-repeat left center;
  background-color: transparent !important;
  padding-left: 3.5rem !important;
}

.mini-modal-button {
  background-color: #e83adc;
  color: white;
  border: none;
  font-family: $font-body;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 1rem;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.close-modal {
  background-color: transparent !important;
  border: none;
  color: white;
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    svg g {
      stroke: $color-babyblue;
      stroke-width: 2px;
      transition: stroke 0.25s ease, stroke-width 0.75s ease;
    }
  }
}

.modal-close {
  width: 0.6rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: $color-keylargo;
  cursor: pointer;
}

.select-file {
  position: relative;

  &.files-check:after {
    background: $color-deepsmoke;
    border-radius: 3em;
    color: $color-babyblue;
    content: "✔";
    display: block;
    height: 1.75rem;
    line-height: 1.5;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    width: 1.75rem;
  }
}

.email-settings-save-confirm {
  color: #9bff9b;
}
