@import "../variables";

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid white; /* must be after border */
  height: 20px;
  margin: 0 auto;
  width: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.container {
  align-items: center;
  display: flex;
}

.loader-text {
  color: $color-litesmoke;
  font-family: $font-body;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  text-transform: initial;
  text-align: left;
}
