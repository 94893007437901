@import "../variables";
@import "../mixins";

/* SHARED */

%font-mono {
  font-family: "Lexend Exa", Oxygen, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  letter-spacing: 0.04em;
}

%square {
  display: block;
  height: 9rem;
  font-size: inherit;
  padding: 1.5rem;
  width: 9rem;

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  span {
    @extend %font-mono;

    display: inline-block;
    font-size: inherit;
    margin-top: 0rem;
    text-align: center;
    width: 100%;
  }
}

:global(.titlebar__title).title,
.subtitle,
.input,
.label-interest {
  @extend %font-mono;
}

/* FORM */

.content-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.finterest-text-description {
  padding: 0 0 1rem 0;
  margin-top: -1rem;
  border-radius: 1rem;
  max-width: 51rem;
  width: calc(100% - 6rem);
}

.finterest-text-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.form {
  font-size: 0.75rem;
  margin: 0 auto;
  width: 100%;

  &.interest-form {
    width: 100%;

    @media only screen and (min-width: em($bp-md)) and (max-width: em($bp-lg)) {
      padding: 0 3rem;
      box-sizing: border-box;
    }
  }

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 1.125em;
    width: 70%;
  }
}

.email-label {
  margin-right: 1em;
}

/* GENERAL */

.wrap {
  max-width: 78em;
  padding: 0 1em;
  margin: 0 auto 1rem;

  @media only screen and (max-width: em($bp-md)) {
    margin-bottom: 7rem;
  }

  &.form {
    margin: 0 auto 0;
    width: 100%;
    padding: 0;

    @media only screen and (max-width: em($bp-md)) {
      padding: 0 3rem;
      box-sizing: border-box;
    }
  }
}

.eth-data-container {
  text-align: center;
}

.eth-data {
  display: inline-block;
  background-color: #0f0f0f;
  border-radius: 2.5em;
  padding: 1.5rem 5rem;
  border-bottom: 3px solid #202020;

  @media only screen and (max-width: em($bp-sm)) {
    padding: 1.5rem 2rem;
  }
}

.middle-links {
  text-align: center;
  margin: 4rem 0 5rem;
}

.middle-links__button {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  border-color: #70d85f;
  color: #70d85f;
  border-radius: 1.5em;

  &:hover {
    background: rgba(112, 216, 95, 0.15);
    box-shadow: 0 0 1.5rem rgba(112, 216, 95, 0.5);
  }

  @media only screen and (max-width: em($bp-sm)) {
    font-size: 1rem;
    padding: 1rem;
  }
}

.middle-links__link {
  display: block;
  font-size: 1.1rem;
}

.recent-investments {
  margin-bottom: 1rem;
}

.recent-investments__header {
  text-align: center;
  padding: 0 1rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.recent-investments__title {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.recent-investments__link {
  text-decoration: none;
  font-size: 0.9rem;

  @media only screen and (min-width: em($bp-lg)) {
    position: absolute;
    right: 0;
    top: 25px;
    margin-right: 1rem;
  }
}

.submit-proposal-container {
  padding: 0 1rem;
}

.submit-proposal {
  border: 3px solid rgba(32, 32, 32, 0.5);
  border-radius: 2.5em;
  padding: 1.5rem 5rem 2rem;
  max-width: 56em;
  text-align: center;
  margin: 0 auto 4rem;
  box-sizing: border-box;

  @media only screen and (max-width: em($bp-md)) {
    padding: 1.5rem 1.5rem 2rem;
  }
}

.submit-proposal__image {
  display: inline-block;
  font-size: 3rem;
  margin-bottom: 0.25rem;
}

.submit-proposal__title {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.submit-proposal__text {
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.75;
}

.submit-proposal__button {
  text-transform: uppercase;
  background-color: #242424;
  border-color: #242424;
  color: #70d85f;
  border-radius: 1.5em;
  font-size: 0.8rem;

  &:hover {
    background: rgba(112, 216, 95, 0.15);
    box-shadow: 0 0 1.5rem rgba(112, 216, 95, 0.5);
  }

  @media only screen and (max-width: em($bp-sm)) {
    font-size: 0.75rem;
    padding: 1rem;
  }
}

:global(.titlebar .titlebar__title).title {
  font-size: 1.2rem;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 1.2rem;
  }
}

.subtitle {
  font-size: 0.9rem;
  margin: 0 0 0;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 0.9rem;
  }
}

.swing {
  animation: swing ease-in-out 0.66s infinite alternate;
  transform-origin: center -20px;
  text-transform: uppercase;
  margin: 0.3rem 0 3rem 0;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.09rem;
  color: #6ef4fa;
}

.sale {
  margin: 4rem 0 0 0;
  font-size: 2rem;
  animation: text-shadow 1.5s ease-in-out infinite;
  letter-spacing: 0.2rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

// project/member stats
.lao-stats {
  text-transform: uppercase;
  margin: 0.3rem 0 2rem 0;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.09rem;
  color: #6ef4fa;
  display: block;
}

/* SQUARE w/ GRADIENT BG */

.square-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 1rem 0 3rem;

  @media only screen and (min-width: em($bp-md)) {
    font-size: 1.125em;
  }
}

.square-bg {
  padding: 0.1875em;
}

.square {
  @extend %square;
}

// radio interest box

.label-interest {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 1.5em;
  text-align: center;

  &__down-arrows {
    color: $color-babypink;
    display: inline-block;
    font-size: 2rem;
    line-height: 1;
    position: relative;
    top: 0.25rem;
    transform: rotate(90deg);
  }
}

.radio-input {
  @include assistive-hidden;

  @media only screen and (min-width: em($bp-md)) {
    margin-right: 1.125rem;
  }
}

.radio-label {
  @extend %square;

  cursor: pointer;
  height: 5em;
  padding: 1em;
  position: relative;
  width: 9em;

  @media only screen and (min-width: em($bp-md)) {
    height: 6em;
    width: 9em;
  }

  img {
    max-height: 40px;

    @media only screen and (min-width: em($bp-md)) {
      max-height: 100%;
    }
  }

  span {
    @extend %font-mono;
    font-size: 4.5rem;

    @media only screen and (min-width: em($bp-md)) {
      font-size: inherit;
    }
  }
}

// interest description

.interest-text__wrap {
  align-items: center;
  background: black;
  border: 2px solid $color-babyblue;
  border-radius: 1rem;
  display: flex;
  font-size: 1em;
  margin: 2em auto 0;
  min-height: calc(12.5em - 3em); /* padding */
  padding: 0.5em 3em;
  width: calc(100% - 7.5em);
}

.interest-text__list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    @extend %font-mono;

    background-image: url("../../images/star-pink-bullet.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1em;
    padding-left: 2.5em;
    margin-top: 1.125em;

    &:first-child {
      margin-top: 0;
    }
  }
}

.interest-text__links {
  margin: 3rem 0;

  @media only screen and (max-width: em($bp-sm)) and (max-width: em($bp-md)) {
    margin: 2rem 0;
  }

  a {
    color: white;
    display: inline-flex;
    border-radius: 25px;
    border: 1px solid white;
    padding: 1em 2em;
    text-decoration: none;
    text-transform: uppercase;
    margin-right: 1rem;

    @media only screen and (max-width: em($bp-sm)) and (max-width: em($bp-md)) {
      box-sizing: border-box;
      margin: 1rem 0 0;
      width: 100%;
    }

    &:hover {
      border: 1px solid $color-babyblue;
      color: $color-babyblue;
    }
  }
}

.interest-text__title {
  font-size: 2rem;
  font-weight: bolder;
}

// input

.input[type="text"] {
  font-size: 1em;
  margin: 1em auto 0;
  width: 100%;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 1.125em;
    width: 60%;
  }
}

// submit

.submit-button[type="submit"] {
  @extend %font-mono;

  cursor: pointer;
  font-size: 1rem;
  margin: 2em auto 0;
  text-transform: uppercase;
  transition: all 0.075s ease;

  &:hover {
    background: rgba(110, 244, 250, 0.15);
    box-shadow: 0 0 1.5rem rgba(110, 244, 250, 0.5);
  }

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 1.125rem;
  }
}

// error

.error {
  font-size: 1rem;
  text-align: center;

  @media only screen and (min-width: em($bp-md)) {
    font-size: 1.125rem;
  }
}

// "thank you" page

.thankyou-text {
  p {
    @extend %font-mono;

    font-size: 1.5em;
    text-align: center;
    margin: 3rem auto 0;

    @media only screen and (min-width: em($bp-md)) {
      margin-left: auto;
      margin-right: auto;
      width: 60%;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.thankyou-social {
  margin: 3rem 0;
  width: 100%;

  @media only screen and (min-width: em($bp-md)) {
    margin: 3em auto;
    width: 50%;
  }
}

.social-icon-wrap {
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  height: 4rem;
  width: 4rem;

  img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }
}

.emoji-alien-wrapper {
  font-size: 6rem;
  color: #fff;
  filter: brightness(80%) grayscale(18%);
  animation: flicker-alien 1.18s infinite alternate;
}

.emoji-moloch-wrapper {
  font-size: 6rem;
  color: #fff;
  filter: brightness(80%) grayscale(18%);
  animation: flicker-alien 1.18s infinite alternate;
  text-align: center;
  margin: 3rem 0 0;
}

.emoji-robot-wrapper {
  font-size: 6rem;
  color: #fff;
  filter: brightness(90%) grayscale(40%);
  animation: flicker-robot 1.18s infinite alternate;
}

.emoji {
  opacity: 1;
}

.green {
  color: #75f869;
  font-weight: 750;
  text-decoration: underline;
}

.stat-container {
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  color: #6ef4fa;

  > span {
    margin: 1rem 0;
  }

  > button {
    text-transform: uppercase;

    &:hover {
      background: rgba(110, 244, 250, 0.15);
      box-shadow: 0 0 1.5rem rgba(110, 244, 250, 0.5);
    }

    @media only screen and (max-width: em($bp-sm)) {
      font-size: 0.75rem;
      padding: 0.75rem;
      margin: 0 1rem;
    }
  }
}

/* Animate neon flicker */

@keyframes text-shadow {
  0% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }

  20% {
    text-shadow: 0 0.03em 0 #dc38d1, 0 0.06em 0 #67e2e7, 0 -0.03em 0 #75f869,
      0 -0.06em 0 #f5f650;
  }

  40% {
    text-shadow: 0 -0.003em 0 #dc38d1, 0 -0.06em 0 #67e2e7, 0 0.03em 0 #75f869,
      0 0.06em 0 #f5f650;
  }

  60% {
    text-shadow: 0 0.015em 0 #dc38d1, 0 0.03em 0 #67e2e7, 0 -0.015em 0 #75f869,
      0 -0.03em 0 #f5f650;
  }

  80% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }
}

@keyframes flicker-alien {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.1rem -0.1rem 0.05rem #fff, 0.1rem 0.1rem 0.05rem #fff,
      0 0 1rem $color-wiltedlilac, 0 0 1.5rem $color-wiltedlilac,
      0 0 2rem $color-wiltedlilac, 0 0 3rem $color-wiltedlilac,
      0 0 4rem $color-wiltedlilac;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

@keyframes flicker-robot {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.1rem -0.1rem 0.05rem #fff, 0.1rem 0.1rem 0.05rem #fff,
      0 0 1rem $color-purple, 0 0 1.5rem $color-purple, 0 0 2rem $color-purple,
      0 0 3rem $color-purple, 0 0 4rem $color-purple;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}
