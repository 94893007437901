@import "../variables";
@import "../mixins";

.contribution-amount {
  margin: 1.8rem 0 0 0;
  font-size: 2rem;
  animation: text-shadow 1.5s ease-in-out infinite;
  letter-spacing: 0.2rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
}

.swing {
  animation: swing ease-in-out 0.66s infinite alternate;
  transform-origin: center -20px;
  text-transform: uppercase;
  margin: 0.3rem 0 2rem 0;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.09rem;
  color: #6ef4fa;
  display: block;
}

.remaining {
  text-align: center;
  margin: 2rem 0 0 0;
  z-index: 12;
  position: relative;
}

@keyframes text-shadow {
  0% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }

  20% {
    text-shadow: 0 0.03em 0 #dc38d1, 0 0.06em 0 #67e2e7, 0 -0.03em 0 #75f869,
      0 -0.06em 0 #f5f650;
  }

  40% {
    text-shadow: 0 -0.003em 0 #dc38d1, 0 -0.06em 0 #67e2e7, 0 0.03em 0 #75f869,
      0 0.06em 0 #f5f650;
  }

  60% {
    text-shadow: 0 0.015em 0 #dc38d1, 0 0.03em 0 #67e2e7, 0 -0.015em 0 #75f869,
      0 -0.03em 0 #f5f650;
  }

  80% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

.subscribed-amount {
  position: relative;
  z-index: 11;
}
