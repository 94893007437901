@import "../variables";
@import "../mixins";

.admin {
  h3 {
    text-decoration: underline;
    text-align: center;
    margin-top: 3rem;
  }

  table {
    width: 100%;
    font-size: 0.8rem;

    th {
      text-align: left;
      text-transform: uppercase;
    }

    td {
      border-bottom: 1px solid white;
      padding: 1rem 0;

      span[role="img"] {
        font-size: 1.25rem;
      }

      &:last-child {
        text-align: center;
      }
    }

    button {
      /* reset */
      border: none;

      background-color: transparent;
      border-radius: 6px;
      box-shadow: 0 0 0 2px #eee;
      color: #eee;
      font-family: inherit;
      min-width: 25%;
      padding: 0.375rem 2rem;
      text-transform: uppercase;
      letter-spacing: 0.0156rem;
      transition: box-shadow 0.25s ease, background-color 0.25s ease;

      &:active,
      &:hover {
        background-color: $color-hotpink;
        box-shadow: 0 0 0 2px darken($color-hotpink, 10%);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:active,
        &:hover {
          // background-color: transparent;
          box-shadow: 0 0 0 2px #eee;
        }
      }

      > div {
        > div {
          height: 10px;
          width: 10px;
        }
        > span {
          font-size: 0.8rem;
        }
      }
    }
  }

  button {
    &.sync {
      width: 1rem;
      height: 1rem;
      margin: 0 0 0 1rem;
      padding: 0;
      background: transparent;
      border: 0;
      color: white;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.4;

        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;

        &:active,
        &:hover {
          background-color: transparent;
          box-shadow: 0 0 0 2px #eee;
        }
      }
    }
  }

  .voted {
    background-color: $color-hotpink !important;
  }

  .processingtable {
    td:last-child {
      display: flex;
      flex-direction: column;

      button {
        &:first-child {
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.admin-member-counts {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;

  > div {
    width: 25%;

    .member-count {
      font-size: 2.4rem;
    }
    > span {
      display: block;
      // text-align: center;
    }
  }
}
