@import "../variables";
@import "../mixins";

/* STYLE USING OPENLAW CLASSES */

:global .align-center {
  text-align: center;
}

:global {
  .list-lvl-1,
  .list-lvl-2,
  .list-lvl-3,
  .list-lvl-4 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

:global .list-lvl-1 {
  text-indent: 0in;
}

:global .list-lvl-2 {
  text-indent: 0.5in;
}

.content {
  p {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      9px,
      14px
    ); /* min font .5625rem, max 0.9rem */
  }

  font-family: $font-serif;
  text-align: justify;
}
