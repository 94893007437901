@import "../mixins";
@import "../variables";
@import "../functions";

.tabbed-header {
  text-align: center;
}

.tabbed-wrapper {
  background-color: #000;
  border-radius: 1rem;
  border: 1px solid #fff;
  margin: 0 auto;
  padding: 1rem;

  @media only screen and (min-width: em($bp-lg)) {
    padding: 2rem;
  }

  h3 {
    display: block;
    width: 100%;
  }
}

.tabbed-wrapper-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  position: relative;
  width: 100%;

  @media only screen and (min-width: em($bp-xl)) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.member-list .tabbed-wrapper-header {
  border: 3px solid #fff !important;
}

.member-list {
  color: yellow;
}

.tab-group {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      text-align: center;

      @media only screen and (min-width: em($bp-xl)) {
        display: inline-flex;
        margin: 0 2rem 0 0;
      }

      .tab {
        background: transparent;
        border: none;
        color: white;
        cursor: pointer;
        font-family: $font-body;
        font-size: 1rem;
        outline: none;
        padding: 1em 0;
        position: relative;

        &.selected {
          &:after {
            border-bottom: 0.3rem solid #6ef4fa;
            bottom: 0;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }
}

.button-group {
  margin: 2rem 0 0 0;

  @media only screen and (min-width: em($bp-xl)) {
    margin: 0;
  }

  button {
    background-color: #e83adc;
    border-radius: 1rem;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 2rem;

    &:disabled {
      background-color: change-color($color: $color-hotpink, $alpha: 0.5);
      color: change-color($color: #fff, $alpha: 0.25);
      cursor: not-allowed;
    }
  }
}

.question-mark {
  width: 1rem;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-left: 0.5rem;

  &:hover {
    opacity: 0.7;
  }
}
