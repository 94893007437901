@import "../variables";
@import "../mixins";

.members-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: 33.33333%;

    @media only screen and (max-width: em($bp-xl)) {
      width: 50%;
    }

    @media only screen and (max-width: em($bp-lg)) {
      width: 100%;
    }
  }
}
