@import "../mixins";
@import "../variables";

.mentor-container {
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;

    @media only screen and (min-width: em($bp-md)) {
      width: 50%;
    }
  }
}

.mentor-introduction {
  text-align: center;

  @media only screen and (max-width: em($bp-md)) {
    p {
      font-size: 1rem;
    }
  }
}

.mentor-name {
  font-weight: bold;
  margin-right: 0.5rem;
}

.mentor-card {
  margin-bottom: 1em;

  .mentor-emoji {
    font-size: 3rem;
    margin-right: 1rem;

    @media only screen and (max-width: em($bp-sm)) {
      font-size: 2rem;
    }
  }

  .mentor-contact {
    display: flex;
    align-items: center;

    width: 100%;

    > div {
      display: flex;
      align-items: center;
    }

    @media only screen and (min-width: em($bp-md)) {
      span {
        width: auto;
      }
    }
  }

  .mentor-bio {
    min-height: 5.75rem;
    margin: 1em 0;

    @media only screen and (max-width: em($bp-md)) {
      font-size: 0.95rem;
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .mentor-bio,
  .mentor-connect {
    padding-left: 0;

    @media only screen and (min-width: em($bp-md)) {
      padding-left: 4rem;
    }
  }

  .mentor-connect {
    display: flex;
    align-items: center;
    > div > a {
      &:first-child {
        margin: 0;
      }
    }
  }

  .mentor-handle {
    font-size: 0.8rem;
  }

  .mentor-social-media {
    margin: 0;
    text-align: left;

    svg {
      width: 1.2rem;
    }
  }
}

.close {
  text-align: right;
}

.mentorModal {
  .mentor-emoji {
    font-size: 3rem;
    margin-right: 1rem;
  }

  .mentor-contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mentor-handle {
    font-size: 0.8rem;
  }
}

.mentor-name-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* MentorCardAlt styles */

.mentor-card--alt .mentor-social-media {
  margin-right: 1rem;
}

.mentor-card--alt .mentor-social-media a {
  height: 20px;
}

.mentor-card--alt .mentor-social-media a:first-child {
  margin: 0;
}

.mentor-card.mentor-card--alt .mentor-bio {
  margin: 1em 0;
}

@media only screen and (max-width: em($bp-md)) {
  .mentor-card--alt .mentor-contact {
    align-items: flex-start;
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .mentor-card--alt .mentor-contact {
    align-items: center;
  }
}
