@import url("https://fonts.googleapis.com/css2?family=Lexend+Exa&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@400;500;600;700;800&family=PT+Mono&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DotGothic16&family=Exo&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Contrail+One&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@font-face {
  font-family: "Halfomania Regular";
  src: url("../fonts/Halfomania/Halfomania-Regular.ttf") format("truetype"),
    url("../fonts/Halfomania/Halfomania-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Potra";
  src: url("../fonts/Potra/Potra.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Trattatello";
  src: url("../fonts/Trattatello/Trattatello.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Inktrap Regular";
  src: url("../fonts/Whyte-Inktrap/Whyte-Inktrap-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Inktrap Medium";
  src: url("../fonts/Whyte-Inktrap/Whyte-Inktrap-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Inktrap Black";
  src: url("../fonts/Whyte-Inktrap/Whyte-Inktrap-Black.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Inktrap Super";
  src: url("../fonts/Whyte-Inktrap/Whyte-Inktrap-Super.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Inktrap Thin";
  src: url("../fonts/Whyte-Inktrap/Whyte-Inktrap-Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Inktrap Light";
  src: url("../fonts/Whyte-Inktrap/Whyte-Inktrap-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Didot Medium";
  src: url("../fonts/Didot/Didot\ Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Didot Regular";
  src: url("../fonts/Didot/Didot\ Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Russian Land Cyrillic Sinod";
  src: url("../fonts/Russian-Land-Cyrillic-Sinod/Russian-Land-Cyrillic-Sinod.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ABCMonumentGrotesk";
  src: url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Regular.otf")
      format("opentype"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Regular.woff")
      format("woff"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Regular.woff2")
      format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ABCMonumentGrotesk";
  src: url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Medium.otf")
      format("opentype"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Medium.woff")
      format("woff"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Medium.woff2")
      format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ABCMonumentGrotesk";
  src: url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Bold.otf")
      format("opentype"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Bold.woff")
      format("woff"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Bold.woff2")
      format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ABCMonumentGrotesk";
  src: url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-RegularItalic.otf")
      format("opentype"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-RegularItalic.woff")
      format("woff"),
    url("../fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-RegularItalic.woff2")
      format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "MarcoSteel";
  src: url("../fonts/MarcoSteel/MarcoSteel-Regular-V3.ttf") format("truetype"),
    url("../fonts/MarcoSteel/MarcoSteel-Regular-V3.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasGrotesk";
  src: url("../fonts/NeueHaasGrotesk/NeueHaasGrotesk-Regular.ttf")
      format("truetype"),
    url("../fonts/NeueHaasGrotesk/NeueHaasGrotesk-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasGrotesk";
  src: url("../fonts/NeueHaasGrotesk/NeueHaasGrotesk-Medium.ttf")
      format("truetype"),
    url("../fonts/NeueHaasGrotesk/NeueHaasGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ARK-ES";
  src: url("../fonts/ARK-ES/ARK-ES-SolidRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
