@import "../variables";

.votes-bar-container {
  position: relative;
  margin-top: 1.5rem;

  flex: 1 1 100%;
}

.votes-bar {
  background-color: $color-babypink;
  border-radius: 25px;
  height: 13px;
  overflow: hidden;
  padding: 0;
  margin: 0 1rem 1rem 1rem;
  position: relative;

  &.nil {
    background-color: white;
  }
}

.bar {
  background-color: $color-babyblue;
  position: absolute;
  left: 0;
  height: 100%;
  display: block;

  &::after {
    content: "";
    position: absolute;
    border-right: 1px solid #848484;
    height: 20px;
    right: 0;
    bottom: 0;
  }
}

.percent-container {
  position: relative;
  margin: 0px 1rem !important;
  height: 13px;
}

.percent {
  position: absolute;
  left: 0;
  height: 100%;
  display: block;
}

div[data-yes-percent]:before {
  content: attr(data-yes-percent);
  position: absolute;
  color: #848484;
  right: -15px;
  text-align: right;
  font-size: 0.8rem;
  top: -28px;
}

div[data-yes-percent]:after {
  right: 0;
  border-right: 1px solid #848484;
  content: "";
  display: block;
  height: 25px;
}

.votes {
  font-size: 1rem;
  margin: 1rem 0 0.5rem;
  position: relative;
}

.yes-percent {
  color: #61d1ff;
  position: relative;
  left: 22%;
}

.no-percent {
  color: #ed9eff;
  position: absolute;
  right: 22%;
}

.yes-check {
  color: #61d1ff;
  position: absolute;
  left: 10%;
  transform: translateY(-4px);

  @media only screen and (max-width: $bp-sm) {
    left: 0;
  }
}

.no-check {
  color: #ed9eff;
  position: absolute;
  right: 10%;
  transform: translateY(-4px);

  @media only screen and (max-width: $bp-sm) {
    right: 0;
  }
}

// SQUARE VOTE BAR STYLES

$square-root-bar-height: 12px;

.square-root-bar {
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border-right: 2px solid #010101;
    height: $square-root-bar-height;
    right: 50%;
    top: 0;
  }
}

.square-root-votes-bar {
  border-radius: 8px;
  height: $square-root-bar-height;
  overflow: hidden;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  background: #3f3f3f;

  .yes-vote,
  .no-vote {
    display: block;
    position: absolute;
    height: $square-root-bar-height;
    transition: 0.4s linear;
  }

  .yes-vote {
    left: 0;
    background-color: #61d1ff;
  }

  .no-vote {
    right: 0;
    background-color: #ed9eff;
  }
}
