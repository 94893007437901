@import "../variables";

.section {
  margin-bottom: 2rem;
}

.heading {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0.75em 0.3em;
  text-align: left;
}

button.button-w-icon {
  align-items: center;
  background: none;
  display: flex;
  font-size: 0.8rem;
  padding: 0;
  transition: color 0.25s ease;

  &:hover {
    color: $color-hotpink;
  }
}

.button-w-icon__text {
  margin-left: 0.5em;
}

.eth-address__wrap {
  align-items: center;
  display: flex;
}

.copy {
  cursor: pointer;
  margin-left: 0.5em;

  &:hover {
    svg g {
      stroke: $color-hotpink;
      transition: 0.25s ease;
    }
  }
}
