@import "../variables";

.members-card {
  align-content: center;
  align-items: center;
  background: #000000;
  border-radius: 3px;
  display: inline-flex;
  height: 6rem;
  word-break: break-word;

  &:hover {
    cursor: pointer;
    color: #75f869;
  }
}

.members-card__link {
  color: inherit;
  text-decoration: none;
}

.connected-member {
  color: #b5fa5d;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  font-family: $font-mono;
  font-size: 0.85rem;

  span {
    margin: 0 1.5rem 0.5rem;
  }

  span:last-of-type {
    margin-bottom: 0;
  }
}

.username {
  font-weight: bold;
  font-size: 1.2rem;
}

.voting-weight {
  opacity: 0.6;
  font-size: 0.6rem;
}

.eth-address {
  opacity: 0.8;
}
