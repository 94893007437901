@import "../variables";

.container {
  outline: none;
}

.text {
  border-radius: 1rem;
  border: 1px dashed white;
  color: $color-litesmoke;
  cursor: pointer;
  display: block;
  padding: 1.125em;
  text-align: center;
  transition: border 0.25s ease;

  &--drag-active {
    border: 1px solid $color-babyblue;
  }
}

.list {
  list-style: none;
}

.listItem {
  color: $color-babyblue;
  display: flex;
  padding: 1rem 2.5rem 1rem 0;
  word-break: break-word;

  svg {
    margin-right: 1rem;
    height: 1.5rem;
  }
}

.file-remove-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  margin-left: 0.5rem;

  /* "X" button */
  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
}
