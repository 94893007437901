@import "../variables";
@import "../functions";
@import "../mixins";

%fluid-font-size {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    12px,
    18px
  ); /* min font .75rem, max 1.125rem */
}

.confirm-instruction-text {
  @extend %fluid-font-size;

  margin-top: 4rem; /* compensate for modal title */
  margin-bottom: 2rem;
  text-align: left;
}

.confirm-instruction-text-list {
  @extend %fluid-font-size;

  margin-top: 1.4rem; /* compensate for modal title */
  text-align: left;
}

.warning {
  border: 3px solid #6ff4fa;
  padding: 1rem;
  margin: 1.4rem 0 0 0;
  border-radius: 1rem;
  display: block;
}

.confirm-instruction-text-note {
  @extend %fluid-font-size;
  margin-top: 3rem; /* compensate for modal title */
  margin-bottom: 1rem;
  text-align: left;
}

.confirm-list {
  @extend %fluid-font-size;

  padding: 0 0 0 2.5em;

  li {
    text-align: initial;
    margin: 0 0 0.6rem 0;

    @media only screen and (min-width: em($bp-md)) {
      text-align: justify;
    }
  }
}

.back {
  position: absolute;
  top: 0;
  right: 0.9rem;
}

.confirm-share-percent,
.confirm-share-amount,
.confirm-share-percent-word {
  color: #fff;
  animation: text-shadow 1.5s ease-in-out infinite;
  letter-spacing: 0.2rem;
  font-weight: bold;
}

.confirm-share-percent-word,
.confirm-share-amount {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    24px
  ); /* min font 1.125rem, max 1.5rem */
}

.confirm-share-percent {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    18px,
    32px
  ); /* min font 1.125rem, max 2rem */
}

.confirm-share-amount-wrap {
  margin-top: 1rem;
}

.confirm-share-amount {
  text-transform: uppercase;
}

.confirm-checkbox-label {
  color: $color-litesmoke;
  font-size: 0.8rem;
  justify-content: center;
  margin: 2rem auto 0;
  text-align: left;
  width: 65%;
}

.completed {
  font-size: 0.9rem;
  margin: 1.6rem 0 0 0;
  color: #b0b0b0;
}

.confirm-buttons {
  display: inline-flex;
  flex-direction: column;
  margin-top: 2rem;
}

.read-terms-button {
  svg {
    margin-right: 1em;
  }

  background-color: transparent;
  border: 1px solid $color-litesmoke;
  border-radius: 1em;
  color: $color-hotpink;
  display: inline-flex;
  font-family: inherit;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1em;
  font-size: inherit;
  transition: border-color 0.25s ease-in-out;

  &:hover {
    border-color: $color-hotpink;
  }
}

.button-back {
  color: $color-litesmoke;
  margin-top: 1.125rem;

  &:hover {
    color: white;
  }
}

.agreement-text-wrap {
  height: calc(100vh - 13rem);
  overflow-y: scroll;
  padding: 0 1rem;
}

/* ANIMATIONS */

@keyframes text-shadow {
  0% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }

  20% {
    text-shadow: 0 0.03em 0 #dc38d1, 0 0.06em 0 #67e2e7, 0 -0.03em 0 #75f869,
      0 -0.06em 0 #f5f650;
  }

  40% {
    text-shadow: 0 -0.003em 0 #dc38d1, 0 -0.06em 0 #67e2e7, 0 0.03em 0 #75f869,
      0 0.06em 0 #f5f650;
  }

  60% {
    text-shadow: 0 0.015em 0 #dc38d1, 0 0.03em 0 #67e2e7, 0 -0.015em 0 #75f869,
      0 -0.03em 0 #f5f650;
  }

  80% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }
}
