.vote-indicator-badge-wrapper {
  text-align: center;
  margin-bottom: 1.5rem;

  &.left {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    margin-bottom: 0;
  }

  &.right {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    margin-bottom: 0;
  }
}

.vote-indicator-badge {
  color: #242424;
  background-color: #6ce460;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  font-size: 0.7rem;

  > span {
    display: block;
    margin: 0.25rem;
  }
}
