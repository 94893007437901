@import "../variables";
@import "../mixins";
@import "../functions";

%row {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 2rem 0;

  &:last-child {
    margin-bottom: 1.5rem;
  }

  @media only screen and (min-width: em($bp-md)) {
    align-items: baseline;
  }
}

.form-description {
  padding: 3rem 3rem 1rem;
  border-radius: 1rem;
  max-width: 51rem;
  width: calc(100% - 6rem);

  p {
    font-size: 1rem;
  }
}

.header-wrap {
  align-items: center;
  position: fixed;
  background: $color-deepsmoke;
  border-radius: 6px;
  display: flex;
  margin: rem(-27);
  width: calc(100% - 56px);
}

.header-title {
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.header-logo {
  margin: rem(27) 0;
  position: relative;
  left: rem(27);
  width: 0; /* so we can evenly align the H2 */
}

.content-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.input-rows-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 51rem;
  width: 100%;
}

.input-row,
.textarea-row {
  @extend %row;
}
.checkbox-row {
  @extend %row;

  @media only screen and (min-width: em($bp-md)) {
    align-items: center;
  }
}

.input-row {
  color: $color-litesmoke;

  &.input-row-column {
    flex-direction: column;
  }
}

.input-row--align-center {
  @media only screen and (min-width: em($bp-md)) {
    align-items: center;
  }
}

.textarea-row {
  align-items: flex-start;

  label {
    margin-top: calc(1rem + 2px);
  }
}

.input-row-label {
  flex: 0 0 26%;
  margin-bottom: 1rem;
  text-align: left;

  @media only screen and (min-width: em($bp-md)) {
    margin-bottom: 0;
    margin-right: 0.5rem;
    max-width: 12rem;
  }
}

.input-row-label-narrow {
  flex-basis: 15%;
}

.input-row-label-top {
  margin-bottom: 1rem;
  width: 100%;
}

.input-row-fieldwrap {
  flex: 0 0 calc(74% - 0.5rem);
  width: 100%;
}

.input-row-fieldwrap textarea {
  border-radius: 1rem !important;
  word-break: break-word;
}

.snapshot-preview + div {
  word-break: break-word;
}

.input-row-fieldwrap-narrow {
  flex-basis: calc(85% - 0.5rem);
}

.input-row-field-split {
  display: inline-flex;
  flex-direction: column;
  width: 47%;

  &:first-child {
    margin-right: 6%;
  }
}

button[type="submit"] {
  display: block;
  margin: 2.5rem auto 1.125rem;
}

// Specificity is to override other error styles
.input-rows-wrap .error {
  font-size: 1rem;
  margin-bottom: 0;
}

.input-row-help {
  color: $color-litesmoke;
  font-size: 1rem;
}

.input-row-explain {
  color: white;
  padding: 0 2%;
}

.member-input-row-explain {
  padding: 0 !important;
  margin-bottom: 2rem !important;
}

.spacer-bottom {
  margin-bottom: 1rem;
}

/* @MEDIA QUERIES */

@include size-tablet-down {
  .header-title {
    margin: 1.75rem 0;
  }

  .header-logo {
    @include assistive-hidden;
  }

  .content-wrap {
    margin-top: 0;
  }

  textarea {
    min-height: rem(150) - rem(18);
  }

  .input-row,
  .textarea-row {
    flex-direction: column;

    .input-row-fieldwrap {
      flex-basis: auto;
      width: 100%;
    }
  }

  .textarea-row {
    label {
      margin-bottom: calc(1rem + 2px);
    }
  }
}

@include size-down-down {
  .header-title {
    margin-left: 1rem;
    text-align: initial;
  }
}
