@import "../functions";
@import "../variables";
@import "../mixins";

%overlay {
  background: rgba(0, 0, 0, 0.85);
  border: none;
  left: 0;
  margin: 0;
  min-height: 100%;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

%content {
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 1.2rem 1.2rem 0 1.2rem;
  box-sizing: border-box;
  color: #fff;
  left: 0;
  margin: 1.5rem;
  outline: none;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
}

.modal-overlay {
  @extend %overlay;
}

.modal-content {
  @extend %content;

  li {
    list-style: none;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  p {
    line-height: 1.1rem;
  }

  :global {
    button,
    .button {
      background-color: #e83adc;
      border-radius: 1em;
      border: none;
      color: white;
      font-family: inherit;
      font-size: 1rem;
      font-weight: bold;
      padding: 1em 2em;
      text-decoration: none;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }

  p {
    line-height: 1.5;
  }
}

.close-modal {
  cursor: pointer;
  position: fixed;
  right: rem(27 * 2);
  top: rem(27 * 2);
  z-index: 10;
}

.mini-modal-content {
  @extend %content;

  font-family: "Lexend Exa", sans-serif;
  margin: 4rem auto;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  width: 36rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // dynamic vertical height
  overflow-y: scroll;
  max-height: calc(100vh - 10em);

  @include size-medium-down {
    width: 100%;

    p {
      font-size: 1rem;
    }

    input[type="text"] {
      margin-bottom: 0.8rem;
    }
  }
}

// Attach this to an immediate child <div> inside a <ReactModal>
// to get a gradient border effect.
.gradient {
  background-image: linear-gradient(0deg, #d485ee 0%, #8ecef0 100%);
  border-radius: 1.125rem;
  padding: 3px;
  position: relative;
  z-index: 10;
}

.modal-content-wide {
  height: calc(100% - (1.5rem * 2)); /* minus the top, bottom margins */
  margin: 1.5rem auto;
  max-width: 62.5rem;
  outline: none;
}

.modal-content-scroll {
  overflow-y: auto;
}

.no-section:first-of-type {
  display: none !important;
}

.supplementary-small {
  font-size: 0.8rem;
  padding-left: 3.75rem;
}

.title {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    20px
  ); /* min font 1.25rem, max 1rem */

  border: none;
  color: white;
  display: inline-block;
  letter-spacing: 2px;
  margin: 1rem 0 0 0; // following <p>'s will distance using their top margin; already enough spacing built-in here.
  padding-bottom: 2rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  &:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%2375f86a' stroke-width='4' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
    background-position: 0 50%;
    background-repeat: repeat;
    background-size: 26px 82px;
    bottom: 0;
    content: "";
    display: inline-block;
    height: 2rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0.9rem;
}

.button-close {
  color: $color-litesmoke;
  margin-top: 1.125rem;

  &:hover {
    color: white;
  }
}

/** MODAL MENU STYLES */

.modal-menu-overlay {
  @extend %overlay;
  background-image: -moz-linear-gradient(
    rgba(151, 81, 157, 0.7) 0%,
    rgba(188, 210, 79, 0.37) 95%
  );
  background-image: -webkit-linear-gradient(
    rgba(151, 81, 157, 0.7) 0%,
    rgba(188, 210, 79, 0.37) 95%
  );
  background-image: linear-gradient(
    rgba(151, 81, 157, 0.7) 0%,
    rgba(188, 210, 79, 0.37) 95%
  );

  transition: opacity 100ms ease-in-out;
}

.modal-menu-content {
  height: calc(100%);
  margin: auto;
  max-width: 62.5rem;
  outline: none;
}

.modal-menu-container {
  background-color: #000;
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  width: 300px;
}

.modal-menu {
  li {
    display: flex;
    margin-top: 1rem;

    &.admin-link a {
      color: #ffff00;
    }

    &.profile-link {
      margin-top: 2rem;

      a {
        color: #6ef4fa;
      }
    }
  }
}

.modal-menu-close {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  width: 1.75rem;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  color: $color-babyblue;
  // outline: none;

  &:hover {
    opacity: 0.7;
  }
}

.modal-title {
  /* override .titlebar__title */
  :global .titlebar {
    margin-bottom: 4rem;
    .titlebar__title {
      max-width: 100%;
    }
  }
}
