@import "../variables";
@import "../functions";
@import "../mixins";

.title {
  color: #fff;
  font-family: $font-body;
  animation: text-shadow 1.5s ease-in-out infinite;
  font-size: 2.2rem;
}

.subtitle {
  font-family: $font-body;
  font-size: 0.9rem;
}

@keyframes text-shadow {
  0% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }

  20% {
    text-shadow: 0 0.03em 0 #dc38d1, 0 0.06em 0 #67e2e7, 0 -0.03em 0 #75f869,
      0 -0.06em 0 #f5f650;
  }

  40% {
    text-shadow: 0 -0.003em 0 #dc38d1, 0 -0.06em 0 #67e2e7, 0 0.03em 0 #75f869,
      0 0.06em 0 #f5f650;
  }

  60% {
    text-shadow: 0 0.015em 0 #dc38d1, 0 0.03em 0 #67e2e7, 0 -0.015em 0 #75f869,
      0 -0.03em 0 #f5f650;
  }

  80% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }
}

.button-wrap {
  display: block;
  margin: 1.8rem 0 0 0;
}

.button {
  display: flex;
  font-family: $font-body;
  margin: auto auto 2.25rem;
  transition: all 0.075s ease;
  position: relative;
  z-index: 13;

  &:hover {
    background: rgba(110, 244, 250, 0.15);
    box-shadow: 0 0 1.5rem rgba(110, 244, 250, 0.5);
  }
}

/* SALE LIST */

.wrap {
  margin: 3rem auto 0;
  max-width: 62.5rem;
  position: relative;

  &.modalWrap {
    text-align: center;
    max-width: 52.5rem;

    @media only screen and (min-width: em($bp-sm)) {
      :global .card {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
      }
    }
  }
}

.sales {
  /* override .card */
  &:global.card {
    max-height: calc(
      100vh - 10rem
    ); /* dynamic height, minus any surrounding margin + padding */
    overflow-y: scroll;
    border: none;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  /* override .titlebar__title */
  :global .titlebar .titlebar__title {
    margin: 0;
  }
}

.gradient {
  background-image: linear-gradient(
    0deg,
    rgb(212, 133, 238) 0%,
    rgb(142, 206, 240) 100%
  );
  border-radius: 1.125rem;
  padding: 3px;
  position: relative;
  z-index: 10;
}

.sale-container {
  -webkit-overflow-scrolling: touch;
  border-radius: 1rem;
  margin-top: 4rem; /* compensate for titlebar */
  max-height: 32.5em;
  overflow-y: auto;
  padding: 0 1em 1em;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 0 1px rgb(212, 133, 238), 0 0 35px blueviolet;
  }

  @media only screen and (min-width: em($bp-md)) {
    max-width: 80%;
    margin: 4rem auto 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--empty {
    border-radius: 1rem;
    margin-top: 4rem; /* compensate for titlebar */
    max-height: 32.5em;
    padding-bottom: 1rem; /* mainly for last row */
  }
}

.current-user-sale {
  color: #b5fa5d;
  position: relative;
  transition: background 0.25s cubic-bezier(0, 0.14, 0, 2.6);

  &:hover {
    background: #302033;
  }
}

.pending-user-sale {
  color: grey;
}

.sale-row {
  align-items: center;
  display: flex;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    10px,
    18px
  ); /* min font-size .625rem, max 1.125rem */
  font-family: $font-mono;
  margin-top: 1em;
}

.sale-row-date {
  flex: 1;
  margin-right: 2em;
}

.sale-row-identity {
  align-items: center;
  display: flex;
  margin-right: 1em;

  &-blockie {
    margin-right: 0.5em;
    text-align: center;

    img {
      height: 1rem;
    }

    @media only screen and (min-width: em($bp-sm)) {
      img {
        height: 1.5rem;
        margin-right: 1em;
      }
    }

    @media only screen and (min-width: em($bp-lg)) {
      img {
        height: 2rem;
      }
    }
  }

  @media only screen and (min-width: em($bp-lg)) {
    margin-right: 4em;
  }
}

.sale-row-percent {
  margin-right: 1em;
  text-align: right;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: inherit;
    margin-right: 1em;
  }
}

.sale-row-amount {
  flex: 1;
  text-align: right;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: inherit;
  }
}

.no-sales-text {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.sale-amount,
.previous-sale-amount {
  font-size: 2rem;
  color: #fff;
  animation: text-shadow 1.5s ease-in-out infinite;
  letter-spacing: 0.2rem;
  font-weight: bold;
  margin: 1rem 0 2rem 0;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 2rem;
  }
}

.sale-amount {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    32px
  ); /* min font 1.5rem, max 2rem */

  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: $font-body;
  justify-content: center;

  // give some spacing to stacked child elements, but not the first.
  > *:not(:first-child) {
    margin-top: 0.5rem;
  }

  &__number-wrap {
    background-image: linear-gradient(0deg, #d485ee 0%, #8ecef0 100%);
    border-radius: 1rem;
    padding: 1px;
  }

  &__number {
    background: black;
    box-shadow: none;
    cursor: pointer;
    border-radius: 1rem;
    padding: 0 1rem;
    transition: box-shadow 0.25s ease;

    &:hover {
      box-shadow: 0 0 0 1px #6ef4fa;
    }
  }

  &__unit {
    margin-left: 0.5rem;
  }

  &__unit-small {
    color: #6ff4fa;
    font-size: 1.2rem;
  }
}

@keyframes text-shadow {
  0% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }

  20% {
    text-shadow: 0 0.03em 0 #dc38d1, 0 0.06em 0 #67e2e7, 0 -0.03em 0 #75f869,
      0 -0.06em 0 #f5f650;
  }

  40% {
    text-shadow: 0 -0.003em 0 #dc38d1, 0 -0.06em 0 #67e2e7, 0 0.03em 0 #75f869,
      0 0.06em 0 #f5f650;
  }

  60% {
    text-shadow: 0 0.015em 0 #dc38d1, 0 0.03em 0 #67e2e7, 0 -0.015em 0 #75f869,
      0 -0.03em 0 #f5f650;
  }

  80% {
    text-shadow: 0 0 0 #dc38d1, 0 0 0 #67e2e7, 0 0 0 #75f869, 0 0 0 #f5f650;
  }
}

.previous-sale-amount {
  color: #abd0ff;
  font-size: 0.85rem;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 1.5rem;
  }
}

.sale-section {
  text-align: center;
  margin: auto;

  @media only screen and (min-width: em($bp-sm)) {
    max-width: 70%;
  }

  svg {
    width: 2rem;
  }

  @media only screen and (min-width: em($bp-sm)) {
    svg {
      width: inherit;
    }
  }
}

.supplementry-text {
  color: #b0b0b0;
  line-height: 1.5;
  margin: 2rem 0 1rem 0;
  text-align: center;
  display: block;
}

.buyer-details {
  align-items: center;
  display: flex;
  justify-content: center;
}

.buyer-ethereum-addr {
  font-family: $font-mono;
  margin-left: 1rem;

  @media only screen and (min-width: em($bp-sm)) {
    font-size: 1.2rem;
  }
}

.sale-instruction {
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  font-size: 0.95rem;
  text-align: left;

  @media only screen and (min-width: em($bp-md)) {
    font-size: 1.125rem;
  }
}
