@import "../variables";
@import "../mixins";

.voting {
  /* override .titlebar__title */
  :global .titlebar {
    margin-bottom: 3rem;
    .titlebar__title {
      max-width: 100%;
    }
  }
}

.voting-button {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  border: none;
  background: #242424;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  flex: 1 1 100%;
  padding: 1rem;
  font-size: 0.9rem;
  border-radius: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.voting-button-group {
  flex: 1 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  padding: 0;

  button {
    border: none;
    background: #242424;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    flex: 1 1 100%;
    padding: 1rem;
    font-size: 0.9rem;
    border-radius: 1rem;

    &.voted {
      &::before {
        content: "\2713";
        padding: 0 0.5rem;
        color: #ffffff;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &:not(:last-of-type) {
      margin-bottom: 1.25rem;
    }
  }
}

.selected[data-vote="1"] {
  background-color: $color-fadedsky !important;
  border: 2px solid $color-fadedsky !important;
}

.selected[data-vote="2"] {
  background-color: $color-babypink !important;
  border: 2px solid $color-babypink !important;
}

.highlighted {
  text-transform: uppercase;

  &.yes {
    color: #6cd6ff;
  }

  &.no {
    color: #e83adc;
  }
}

.emoji {
  font-size: 3rem;
}

.recording-msg {
  color: #85d3ff;
  font-size: 0.8rem;
  position: relative;

  > div {
    position: relative;
    display: inline;
  }

  svg {
    width: 1.8rem;
  }

  &.success {
    margin-bottom: 1.5rem;
    text-align: center;
    color: rgb(187, 187, 78);
  }
}

.process-btn {
  > div > span {
    margin-left: 0.5rem;
    // text-transform: initial;
    font-size: 0.95rem;
  }
}

.view-progress {
  font-size: 1rem;
  margin-top: 0;
  text-align: center;
}

.close {
  position: absolute;
  top: 0;
  right: 0.9rem;
}

.button-close {
  color: $color-litesmoke;
  margin-top: 1.125rem;

  &:hover {
    color: white;
  }
}

.why--tooltip {
  font-size: 0.75rem;
  float: right;
  opacity: 0.7;
  cursor: pointer;
  border-bottom: 2px dotted white;
  display: inline-flex;
  margin-top: 0;
  color: white;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
}

.delegate-info-small {
  font-size: 0.75rem;
  text-align: center;
  display: inline-block;
  margin-top: 3rem;
  opacity: 0.7;
}

.processing-text {
  font-size: 0.8rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.7;
}
