@import "../variables";
@import "../mixins";

.checkbox {
  @include assistive-hidden;

  cursor: pointer;
  z-index: 100;
}

.container {
  align-items: center;
  display: inline-flex;
  // @note Change this to change the size of the entire component.
  font-size: 2.5rem;
}

.toggle-text {
  cursor: pointer;
  font-size: 0.33em;
  transition: color 0.2s ease;
  user-select: none;

  &--selected {
    color: $color-babyblue;
  }

  &__off {
    margin-right: 0.5em;
  }

  &__on {
    margin-left: 0.5em;
  }
}

.switch {
  align-items: center;
  background: $color-darksmoke;
  border-radius: 0.625em;
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  height: 0.75em;
  position: relative;
  width: 1.75em;

  &--off {
    box-shadow: 0 0 0 0.025em $color-brightlime inset;
    .switch__emoji {
      left: 0;
    }
  }

  &--on {
    box-shadow: 0 0 0 0.025em $color-hotpink inset;
    .switch__emoji {
      // This subtracts relative to 1 unit of the emoji font-size, whatever the size.
      left: calc(100% - 1em);
    }
  }

  &__emoji {
    position: absolute;
    // Get rid of empty space on top/bottom of character
    line-height: 1;
    transition: left 0.2s ease;
    user-select: none;
  }
}
