@import "../variables";

.row-w-icon {
  align-items: center;
  display: flex;
  margin-top: 1.25rem;
  justify-content: center;

  &:first-of-type {
    margin-top: 1.5rem;
  }

  svg {
    flex-shrink: 0;
    margin-right: 1rem;
  }
}

.countdown,
.required-votes {
  font-size: 0.8rem;
}

.required-votes {
  display: flex;
  flex-wrap: wrap;

  span {
    font-size: 0.7rem;
    display: inline-flex;
    align-items: center;
  }
}

.count-until-starts {
  color: #3bc5ff;
}

.count-until-ends {
  color: #6ce460;
}

.last-seconds {
  color: $color-brightsalmon;
}

.timer {
  font-family: "Space Mono", monospace;
  margin-left: 0.5rem;
  font-size: 0.85rem;
}

.status {
  color: #6ce460;
  text-transform: uppercase;

  &.status--failed {
    color: #ed9eff;
  }

  &.status--starts {
    color: #3bc5ff;
  }

  &.status--ended {
    color: #ffffff;
  }
}

.yesVote {
  color: $color-babyblue;
}

.noVote {
  color: $color-babypink;
}

.divider {
  color: $color-darksmoke;
  display: inline-block;
  margin: 0 0.5rem;
}

.remaining {
  color: $color-litesmoke;
}

.tokendetails__title {
  font-size: 0.6rem;
  text-transform: uppercase;
  flex: 1 100%;
  display: flex;
  margin: 0.5rem 0;
}

.tokendetails__details {
  flex: 1 100%;
  display: flex;
}

.tokendetails__tokenname {
  color: #939393;
  font-size: 0.7rem;
  margin-left: 0.5rem;
  position: relative;
  top: 4px;
}

.processed-tx {
  border-right: 1px solid #333333;
  padding-right: 1rem;
  margin-right: 1rem;
}

.submit-proposal-btn {
  > div > span {
    margin-left: 0.5rem;
    text-transform: initial;
    font-size: 0.95rem;
    text-align: left;
  }
}

.withdraw-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin-right: 0.5rem;
  }
}
