@import "../variables";
@import "../mixins";
@import "../functions";
@import "./buttons.module";

.radio-button[type="radio"] {
  position: relative !important;
  margin-right: 2.5rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  clip: initial;
  overflow: initial;

  &::before {
    content: "";
    display: block;
    width: 30px;
    position: absolute;
    height: 30px;
    background-color: $color-babyblue;
    padding: 0px;
    margin: 1px;
    border-radius: 25px;
    top: -7px;
    left: -7px;
  }

  &::after {
    content: "";
    display: block;
    width: 17.5px;
    position: absolute;
    height: 17.5px;
    background-color: white;
    border: 4px solid white;
    border-radius: 25px;
    top: -3.5px;
    left: -3.5px;
  }

  &:checked {
    &::after {
      background-color: $color-babyblue;
    }
  }
}

/* RADIO YES/NO/OTHER HORIZONTAL BUTTONS */

.radio-yesno-question {
  color: white;
}

.radio-yesno[type="radio"] {
  @include assistive-hidden;
}

.radio-yesno[type="radio"] {
  & + label {
    @extend %button-base;

    cursor: pointer;
    display: inline-block;
    margin-right: 1rem;
    margin-top: 1rem;
    outline: none;
    transition: all 0.25s ease;

    &:last-of-type {
      margin-right: 0;
    }

    &:focus,
    &:active,
    &:hover {
      @extend .primary;
    }
  }

  &:focus + label {
    @extend .primary;
  }

  &:checked + label {
    border-color: $color-babyblue;
    color: $color-babyblue;
  }
}
