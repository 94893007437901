@import "../variables";
@import "../mixins";

.alert-text {
  color: $color-sunny;
}

.withdraw-balances {
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

.withdraw-balances__list {
  padding: 0;
  list-style-type: none;
}

.withdraw-button {
  display: block;
  margin: 0 auto;
}
