@import "../variables";
@import "../mixins";

.balances-container {
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.eth-amount {
  font-size: 0.9rem;
  letter-spacing: 0.09rem;
}

.usd-amount {
  margin: 1rem 0;
  font-size: 2rem;
  letter-spacing: 0.2rem;
}

.usd-amount--contributed {
  color: #6df4fa;
}

.usd-amount--deployed {
  color: #f790da;
}
