@import "../variables";
@import "../functions";

%nav {
  display: flex;
  margin-top: 3rem;
}

.wrap {
  max-width: 60em;
  padding: 0 1em;
  margin: 0 auto 4rem;
}

/* FLEX */

.flex-container {
  display: flex;
  flex-direction: column;

  // medium screens and up
  @media only screen and (min-width: em($bp-md)) {
    flex-direction: row;
  }
}

/* NAVIGATION */

.nav--left {
  @extend %nav;

  justify-content: flex-start;
}

.nav--right {
  @extend %nav;

  justify-content: flex-end;
}

.nav--split {
  @extend %nav;

  justify-content: space-between;

  // medium screens and down
  @media only screen and (max-width: em($bp-md)) {
    flex-direction: column;

    button {
      margin-top: 1rem;
    }
  }
}

/* MULTIPLE INPUTS ON ONE ROW */

.inputs-row {
  > div {
    margin: 1rem 1.125rem 0 0;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    // medium screens and up
    @media only screen and (min-width: em($bp-md)) {
      margin-top: 0;
      width: auto;

      &.inputs-row-item--w40 {
        width: 40%;
      }
    }
  }
}

/* FORM SECTIONS */

.form-section-heading {
  color: $color-babyblue;
  margin-top: 2.75em;
}

/* ADD/REMOVE COLLECTION */

.collection-label {
  margin-top: 0;

  &--first {
    margin-top: 1rem;

    span {
      margin-top: 0;
    }
  }

  span {
    margin-top: 0;
  }
}

.collection-add-button {
  margin-top: 1rem;
}

.collection-remove-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;

  /* "X" button */
  svg {
    height: 1.125rem;
    width: 1.125rem;
    margin-top: 1.125rem;
  }
}

.submit-error {
  text-align: center;
  margin-top: 1.5rem;
}
