@import "../mixins";

.index {
  // height: 180vh;
}

.content {
  position: relative;
  z-index: 9;
  margin: 3rem auto;
}

.video {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: brightness(18%) grayscale(100%);
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.presents {
  position: absolute;
  top: 0rem;
  z-index: 10; //4;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  left: 480px;
  transform: rotate(-20deg);
  opacity: 0.4;

  a {
    text-decoration: none;
    color: white;
  }

  &:hover {
    opacity: 1;
  }

  @include size-medium-down {
    left: 16px;
  }
}

.title {
  width: 100%;
  text-align: center;
  padding: 2.4rem 0 0 0;
  font-family: "Potra", "Halfomania Regular";
  letter-spacing: 0.5rem;
  z-index: 2;
  font-weight: 400;
  line-height: 9rem;

  @include size-medium-down {
    line-height: 5rem;
  }
}

.sub-title {
  width: 100%;
  color: #fff;
  text-align: center;
  z-index: 2;
  font-family: "Lexend Exa", sans-serif;
  margin-top: 2.5rem;
}

.green {
  color: #73f769;
}

.blue {
  color: #6ef4f9;
}

.yellow {
  color: #f2f350;
}

.violet {
  color: #e73adb;
}

.orange {
  color: #ff5a17;
}

.spacer {
  margin-left: 3rem;
}

.presents img {
  width: 160px;

  @include size-medium-down {
    width: 130px;
  }
}

.buttons-container {
  text-align: center;
  margin: 3rem 0;
}

.button1,
.button2 {
  color: white;
  cursor: pointer;
  border: none;
  margin: 0 6rem;
  font-size: 1.3rem;
  padding: 0.8rem 0;
  letter-spacing: 2px;
  transition-property: transform;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  text-transform: uppercase;
  display: inline-block;
  font-family: "Lexend Exa", sans-serif;
}

// .button1 {
//   // background: linear-gradient(90deg, rgba(2, 0, 36, 0.165704) 0%, var(--button-text-color) 49%, rgba(41, 45, 46, 0.227328) 100%);
//   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{}@keyframes shift {}to {}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
//   // background-position: 0 100%;
//   // background-repeat: no-repeat;
//   // background-size: 100% 3px;
//   background-position: 0 95px;
//   background-size: 26px 82px;
//   background-repeat: repeat;
//   text-decoration: none;
// }

// .button1:hover {
//   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
//   background-position: 0 100%;
//   background-size: auto 6px;
//   background-repeat: repeat-x;
//   text-decoration: none;
//   transform: scale(1.2) rotate(-6deg);
//   color:#fff;
// }

.button1,
.button2 {
  // background: linear-gradient(90deg, rgba(2, 0, 36, 0.165704) 0%, var(--button-text-color2) 49%, rgba(41, 45, 46, 0.227328) 100%);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%236ef4f9' stroke-width='4' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  // background-position: 0 100%;
  // background-repeat: no-repeat;
  // background-size: 100% 3px;
  background-position: 0 88px;
  background-size: 26px 82px;
  background-repeat: repeat;
  text-decoration: none;
}

.button1:hover,
.button2:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%236ef4f9' stroke-width='4' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  // background-position: 0 100%;
  // background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
  background-position: 0 20px;
  background-size: 11% 100%;
  transform: scale(1.2) rotate(6deg);
  color: #fff;
}

.social-media-wrapper {
  z-index: 99;
  position: relative;
  text-align: right;
  margin: 2rem;

  a {
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0 1.2rem;

    &:hover {
      color: #ffffff;
    }
  }
  svg {
    width: 1.2rem;

    @include size-medium-down {
      width: 1.5rem;
    }
  }
}
