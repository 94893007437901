@import "./variables";
@import "./mixins";
@import "./fonts";
@import "./functions";
@import "./grid";

html {
  height: 100%;
  font-size: 100%; /* 16px by default; px value overrides user's browser settings */
}

/* body */

html,
body {
  color: #000000; /* `thelao` org color set in `thelao` org style.css; default color set here so InitError can show for other orgs before they can load */
  background-color: transparent; /* `thelao` org background-color set in `thelao` org style.css; default background-color set here so `thelao` org background-color doesn't show for other orgs before they can load */
  font-weight: normal;
  font-family: $font-body;
}

body {
  margin: 0;
}

/* headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
  font-weight: 500;
}

header {
  z-index: 9;
  position: relative;
}

main {
  flex: 1; /* sticky footer */
  z-index: 0; /* header nav needs to appear on top of <main> */
}

// REACT MOUNT POINT
#root {
  /* sticky footer */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* end sticky footer */
}

.titlebar {
  display: block;
  text-align: center;
  width: 100%;
}

.titlebar__title {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    22.4px
  ); /* min font-size 1rem, max 1.4rem */
  display: inline-block;
  letter-spacing: 2px;
  margin: 3.6rem 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (min-width: em($bp-lg)) {
    max-width: 50%;
  }

  &:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%2375f86a' stroke-width='4' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
    background-position: 0 50%;
    background-repeat: repeat;
    background-size: 26px 82px;
    bottom: -1.5rem;
    content: "";
    height: 0.7rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &--compact {
    margin: 1rem 0 1.5em;
  }
}

.titlebar__action {
  background-color: #242424;
  border-radius: 1rem;
  border: none;
  color: white;
  display: block;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 auto 2rem;
  padding: 1rem;

  @media only screen and (min-width: em($bp-xl)) {
    position: absolute;
    right: 0;
    top: 110px;
  }

  .titlebar__action-text {
    margin-left: 1rem;
  }
}

p {
  color: white;
  font-size: rem(18);
}

a {
  color: $color-babyblue;
}

/* layout */

.wrap {
  margin: 0 auto;
  display: flex;
  width: 100%;
}

.wrap-large {
  max-width: $width-wide;
}

/* layout: flex rows & columns */
// TODO add better rows columns
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

%column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.column {
  @extend %column;

  @include size-large-up {
    flex: 1;
  }
}

.column-30 {
  @extend %column;

  @include size-large-up {
    flex: 0.3;
  }
}

/* layout: divider */

hr.divider {
  /* reset */
  border: none;

  border-top: 1px solid $color-deepsmoke;
  margin: 1.5rem 0;
  width: 100%;
}

/* cards */

.card {
  background: #000;
  border-radius: 1rem;
  border: 1px solid #fff;
  margin: 0 0 2rem;
  padding: 2rem;
}

/* font */

.font-mono {
  font-family: $font-mono;
}

/* font-color */

.color-white {
  color: white;
}

.color-yellow {
  color: $color-sunny;
  position: relative;
  z-index: 20;
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: #{$color};
  }
}

/* font decoration */

.underline--wavy {
  text-decoration-line: underline;
  text-decoration-style: wavy;
}

/* alignment */

.text-center {
  text-align: center;
}

/* errors */

.error-message {
  color: $color-brightsalmon;
  // the text needs to wrap as the JSON erros can be long
  word-break: break-word;
}

/* helpers */

.hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

// Helpful when we don't want screen readers, or testing DOMs to show the content either.
.hidden-no-accessibility {
  display: none;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */
}

.image-wrap {
  position: relative;
  width: 100%;
}

nav {
  ul {
    li {
      display: inline-flex;
      margin: 0 0 0 2rem;

      a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;

        &.active,
        &:hover {
          color: $color-babyblue;
        }
      }
    }

    margin-right: 2rem;
  }
}

.section-wrapper {
  flex: 1 1;
  margin: 0 0.75rem 9rem;

  @media only screen and (min-width: em($bp-lg)) {
    margin: 0 3rem 9rem;
  }
}

.why__tooltip {
  font-size: 0.75rem;
  float: right;
  opacity: 0.7;
  cursor: pointer;
  border-bottom: 2px dotted white;
  display: inline-flex;
  margin-top: 0;
  color: white;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  &--left {
    float: none;
    margin-top: 0.25rem;
  }
}

/** MetaMask SVG Inline Styles */
.st0 {
  fill: #e17726;
  stroke: #e17726;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st1 {
  fill: #e27625;
  stroke: #e27625;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st2 {
  fill: #d5bfb2;
  stroke: #d5bfb2;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st3 {
  fill: #233447;
  stroke: #233447;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st4 {
  fill: #cc6228;
  stroke: #cc6228;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st5 {
  fill: #e27525;
  stroke: #e27525;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st6 {
  fill: #f5841f;
  stroke: #f5841f;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st7 {
  fill: #c0ac9d;
  stroke: #c0ac9d;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st8 {
  fill: #161616;
  stroke: #161616;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st9 {
  fill: #763e1a;
  stroke: #763e1a;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.no-section {
  line-height: 1 !important;
}

.align-right-three-quarters {
  margin-left: 60%;
}

.pulse {
  animation: pulse 0.9s infinite ease-in-out alternate;
}
@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}

.emoji-error {
  margin-top: 2.2rem;
  font-size: 6rem;
}

/** Settings - Page Styles */
.email-settings {
  margin: 0 15%;

  input,
  textarea {
    margin-bottom: 1rem;
  }
}

/* Syntax highlighting for textarea (html/css) Editor component in admin */
.container__editor {
  font-size: 12px;
  font-variant-ligatures: common-ligatures;
  background-color: black;
  border-radius: 3px;
  border: 1px solid white;

  textarea {
    outline: 0;
  }
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #90a4ae;
}
.token.punctuation {
  color: #9e9e9e;
}
.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #e91e63;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #4caf50;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #795548;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: yellow; // #3f51b5;
}
.token.function {
  color: #f44336;
}
.token.regex,
.token.important,
.token.variable {
  color: #ff9800;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}

// COMMON ORG STYLES

.org-proposal-details .org-voting-delegated {
  display: none;
}

// PROPOSALS MAIN CARD GRID LAYOUT
.sections-grid-container {
  background-color: #000;
  margin: 0 auto;
  padding: 1rem;
}

.sections-grid__header {
  display: inline-block;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 0.3rem solid #6ef4fa;
}

.sections-grid__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
  margin-bottom: 2.75rem;

  @media only screen and (max-width: em($bp-lg)) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: em($bp-sm)) {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }

  &--center-one {
    > div {
      grid-column-start: 2;
    }

    @media only screen and (max-width: em($bp-lg)) {
      grid-template-columns: 1fr 50% 1fr;
    }

    @media only screen and (max-width: em($bp-sm)) {
      grid-template-columns: 1fr;
      margin-bottom: 2rem;

      > div {
        grid-column-start: 1;
      }
    }
  }
}

.no-data-container {
  margin: 2rem 0;
}

.emoji-container {
  font-size: 3em;
  width: 3rem;
  margin: 0 auto;
}

.status-monitor {
  &.status-monitor-rejected {
    color: red;
  }
  &.status-monitor-standby,
  &.status-monitor-pending {
    color: orange;
  }
  &.status-monitor-fulfilled {
    color: greenyellow;
  }
}

.proposal-card--link {
  color: inherit;
  text-decoration: none;
}

// ADMIN DEPLOY CONTRACT

.admin-deploy-contract {
  width: 50%;
  margin: auto;

  label {
    display: block;
    margin: 1.5rem 0 0.5rem;
  }

  button {
    margin-top: 2rem;
  }
}

// WALLET OVERRIDES

.walletconnect-modal__mobile__toggle a {
  color: #000000;
}

.org-modal-menu-container {
  .org-get-connected-btn--error {
    span:last-child {
      display: none !important;
    }
  }
}

// SIDEPOCKET PROPOSAL STATUS

.org-proposal-status--container {
  text-align: center;

  small {
    font-size: 0.9rem;
    opacity: 0.7;
  }
}

// SOCIAL AVATAR  -  DISCORD

.org-input-prefix-discord {
  color: inherit;

  svg {
    color: rgba(255, 255, 255, 0.5);
    height: 2rem;
    width: 2rem;
  }
}

.org-discord-redirect {
  color: #f5f650;
  margin-top: 1rem;
}

.org-social-avatar-container {
  display: flex;
  display: -webkit-flex;

  svg.discord-svg {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
  }

  svg.fa-check-circle {
    color: #00e400;
    height: 1.25rem;
    width: 1.25rem;
  }

  .org-identity-details {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-right: 1rem;
    align-items: center;

    p {
      margin: 0;
      align-items: center;
      display: flex;
      display: -webkit-flex;
    }
  }

  .org-identity-username {
    align-items: center;
    display: flex;
    display: -webkit-flex;

    @media only screen and (max-width: em($bp-sm)) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .input-discord-username {
    background-color: transparent;
    border: 0;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    width: auto;

    @media only screen and (max-width: em($bp-sm)) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 160px !important;
    }
  }

  .button-discord-disconnect {
    background-color: transparent;
    border: 0;
    color: inherit;
    font-family: inherit;
    margin-left: 1rem;
    font-size: 1rem;
    color: #ff6f6f;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
      transition: opacity 0.3ms ease-in-out;
    }

    @media only screen and (max-width: em($bp-sm)) {
      margin: 0;
      padding: 0;
    }
  }

  button {
    margin-top: 0;
  }
}
