@import "../variables";
@import "../mixins";

.remaining {
  text-align: center;
  margin: 1.2rem 0 0 0;
  z-index: 12;
  position: relative;
}

$rainbow: (
  green: #75f869,
  yellow: #e73adb,
  blue: #6ff4fa,
);
$i: 1;
$borderWidth: 30px;
$dimension: 90px; // (dimenison is the total = number of rainbow stripes * borderWidth)
$offset: $dimension - $borderWidth;
$clip: $dimension + ($borderWidth * 2);
$z: length($rainbow);

@each $color, $value in $rainbow {
  .rainbow-container .rainbow-stripe:nth-child(#{$i}) {
    border-color: $value;
    clip: rect(0, $clip, ($clip / 2), 0);
    height: $dimension;
    width: $dimension;
    left: $offset;
    top: $offset;
    z-index: $z;

    -webkit-transform: rotate(360deg); // starting deg
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);

    -webkit-animation: rainbow-in-out
      length($rainbow) +
      s
      #{$i /
      (length($rainbow) * 2)}s
      1;
    -moz-animation: rainbow-in-out
      length($rainbow) +
      s
      #{$i /
      (length($rainbow) * 2)}s
      1;
    animation: rainbow-in-out
      length($rainbow) +
      s
      #{$i /
      (length($rainbow) * 2)}s
      1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    &:before {
      border: ($borderWidth/8) solid darken($value, 10%); // ie. borderWidth/4
      content: "";
      display: block;
      height: $dimension - $borderWidth;
      width: $dimension - ($borderWidth / 2);
    }
  }

  .rainbow-container .rainbow-stripe:first-child {
    box-shadow: 1px 2px 5px white inset;

    &:before {
      border: none;
    }
  }

  $i: $i + 1;
  $dimension: $dimension + ($borderWidth * 2);
  $offset: $offset - $borderWidth;
  $clip: $dimension + ($borderWidth * 2);
  $z: $z - 1;
}

.sale-unicorn-chart-container {
  display: flex;
  height: $dimension / 2;
  justify-content: center;
  overflow-y: visible;
  position: relative;
}

.rainbow-container {
  height: $dimension / 2;
  width: $dimension;
  position: absolute;
  top: 0;

  .rainbow-stripe {
    border-width: $borderWidth;
    border-style: solid;
    border-radius: 100%;
    position: absolute;

    &:before {
      border-radius: 100%;
    }
  }
}

.mask-segment {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  position: absolute;
  width: 100%;
  transform-origin: bottom center;
  box-sizing: border-box;
  transition: all length($rainbow) + s #{$i / (length($rainbow) * 2)}s;
  overflow: visible;
  z-index: 10;

  &::before {
    display: block;
    position: absolute;
    font-size: 7rem;
    overflow: visible;
    animation: flicker-unicorn 1.8s infinite alternate;

    transform: rotate(80deg) scaleX(-1);
    top: 85px;
    left: 165px;
  }
}

.subscribed-amount {
  position: relative;
  z-index: 11;
}

@keyframes flicker-unicorn {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.1rem -0.1rem 0.05rem #fff, 0.1rem 0.1rem 0.05rem #fff,
      0 0 1rem $color-wiltedlilac, 0 0 1.5rem $color-wiltedlilac,
      0 0 2rem $color-wiltedlilac, 0 0 3rem $color-wiltedlilac,
      0 0 4rem $color-wiltedlilac;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

@-webkit-keyframes rainbow-in-out {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rainbow-in-out {
  0% {
    -moz-transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes rainbow-in-out {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
