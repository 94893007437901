@import "../variables";
@import "../mixins";
@import "../functions";

.proposal-card {
  background-color: #010101;
  border-radius: 1rem;
  border: 1px solid #737373;
  padding: 2rem;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &.proposal-card--private:hover {
    cursor: initial;
  }

  > p,
  > div {
    color: #fff;
    font-size: 0.8rem;
  }

  .proposal-icon {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .proposal-icon-governance {
    font-size: 3rem;
    background-color: #242424;
    border-radius: 50%;
    padding: 6px;
    height: 48px;
    width: 48px;
    line-height: 48px;
    text-align: center;
    display: inline-block;
  }

  .proposal-title {
    font-size: 1.25rem;
    font-weight: 1000;
    line-height: 1.6;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .proposal-button {
    font-family: "Lexend Exa", sans-serif;
    background: #242424;
    border-radius: 12px;
    border: 1px solid #242424;
    color: white;
    flex: 1 1 100%;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 1.5rem;
    padding: 0.6rem 1rem;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  p:nth-child(3n) {
    &:before {
      top: 16px;
    }
  }
}
