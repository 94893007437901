@import "../variables";
@import "../functions";
@import "../mixins";

.member-details-wrapper {
  background: black;
  padding: 1rem;
  display: flex;
  flex: 1 100% !important;
  margin: 2rem 0;
  border: 1px solid #737373;
  border-radius: 1rem;

  @include size-medium-down {
    flex-direction: column;
  }

  @media only screen and (min-width: em($bp-xl)) {
    margin-top: 3.5rem;
  }
}

.member-column {
  padding: 1.5rem 5rem 1.5rem 1rem;
  flex: 0 45% !important;
  min-width: 45% !important;

  @include size-medium-down {
    padding: 1rem;
  }

  p {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      12px,
      16px
    ); /* min font-size 0.75rem, max 1rem */

    line-height: 1.6;
    white-space: pre-line;
  }
}

.voting-history-column {
  padding: 1.5rem 2rem;
  // flex: 1;
  flex: 0 55% !important;

  @include size-medium-down {
    padding: 1rem;
  }
}

.error {
  color: $color-brightsalmon;
  margin-top: 3rem;
  text-align: center;
  width: 100%;
}

.no-voting-history {
  // text-align: center;
  color: rgb(245, 246, 80);
  font-size: 1rem;
}

.voting-history-title {
  font-size: 1.125rem;
  font-weight: 900;
  margin: 2.5rem 0;
}

.update-field {
  display: block;
  margin: 1rem 0;

  &:last-of-type {
    margin-bottom: 2rem;
  }
}
